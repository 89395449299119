import classNames from 'classnames'
import Img from 'gatsby-image'
import React, { useState, useRef } from 'react'

import AppsCarousel from 'components/carousel/AppsCarousel'
import PageHead from 'components/head/PageHead'
import Heading from 'components/heading/Heading'
import PageWrapper from 'components/layout/page-wrapper'
import AppsData from 'data/pages/Contents/apps.json'
import buildImageObject from 'js/utils/buildImgObject'
import { useRellax, useWindowEvent } from 'js/utils/hooks'
import ExodusSymbol from 'static/img/exodus-symbol.svg'

import 'static/apps/scss/styles.scss'

const images = {
  headerSection: {
    aspectRatio: 1.63,
    path: '/apps/img/header-bg/',
    fileType: 'jpg',
    files: [
      {
        fileName: 'header-bg-ssize',
        width: 640,
      },
      {
        fileName: 'header-bg-msize',
        width: 1536,
      },
      {
        fileName: 'header-bg-lsize',
        width: 3200,
      },
    ],
  },

  featuredSection: {
    rewards: [
      {
        aspectRatio: 1.54,
        path: '/apps/img/carousel/rewards/',
        fileType: 'png',
        webp: true,
        files: [
          {
            fileName: 'rewards-carousel1-lsize',
            width: 2100,
          },
          {
            fileName: 'rewards-carousel1-msize',
            width: 1536,
          },
          {
            fileName: 'rewards-carousel1-ssize',
            width: 640,
          },
        ],
      },
      {
        aspectRatio: 1.54,
        path: '/apps/img/carousel/rewards/',
        fileType: 'png',
        webp: true,
        files: [
          {
            fileName: 'rewards-carousel2-lsize',
            width: 2100,
          },
          {
            fileName: 'rewards-carousel2-msize',
            width: 1536,
          },
          {
            fileName: 'rewards-carousel2-ssize',
            width: 640,
          },
        ],
      },
      {
        aspectRatio: 1.54,
        path: '/apps/img/carousel/rewards/',
        fileType: 'png',
        webp: true,
        files: [
          {
            fileName: 'rewards-carousel3-lsize',
            width: 2100,
          },
          {
            fileName: 'rewards-carousel3-msize',
            width: 1536,
          },
          {
            fileName: 'rewards-carousel3-ssize',
            width: 640,
          },
        ],
      },
      {
        aspectRatio: 1.54,
        path: '/apps/img/carousel/rewards/',
        fileType: 'png',
        webp: true,
        files: [
          {
            fileName: 'rewards-carousel4-lsize',
            width: 2100,
          },
          {
            fileName: 'rewards-carousel4-msize',
            width: 1536,
          },
          {
            fileName: 'rewards-carousel4-ssize',
            width: 640,
          },
        ],
      },
      {
        aspectRatio: 1.54,
        path: '/apps/img/carousel/rewards/',
        fileType: 'png',
        webp: true,
        files: [
          {
            fileName: 'rewards-carousel5-lsize',
            width: 2100,
          },
          {
            fileName: 'rewards-carousel5-msize',
            width: 1536,
          },
          {
            fileName: 'rewards-carousel5-ssize',
            width: 640,
          },
        ],
      },
    ],
  },
}

const HeaderSection = ({ data }) => {
  useRellax({ className: 'apps-rellax' })

  return (
    <header className="x-header">
      <div className="x-header__x apps-rellax" data-rellax-speed="-8" role="presentation" />
      <div
        className="x-header__bg-container apps-rellax"
        data-rellax-speed="-4"
        role="presentation"
      >
        <div className="x-header__bg">
          <Img
            fluid={buildImageObject(images.headerSection)}
            durationFadeIn={2000}
            alt={data.imageAlt}
          />
        </div>
      </div>
      <div className="x-header__content">
        <img src={ExodusSymbol} className="x-page-apps__header-logo" />
        <div className="x-header__txt-container">
          <span className="x-header__subheading">{data.subheading}</span>
          <Heading page="apps" />
        </div>
      </div>
    </header>
  )
}

const AppListSection = ({ data }) => {
  return (
    <section className="x-page-apps-list">
      <div className="x-page-apps__subheading">{data.subheading}</div>
      <div className="x-page-apps-list__img" />
    </section>
  )
}

const DescriptionSection = ({ data }) => {
  return (
    <section className="x__width">
      <div className="x-page-apps-desc">
        <span className="x-page-apps-desc__title">{data.heading}</span>
        <div className="row">
          {data.description.map((description, d) => {
            return (
              <div key={d} className="col-md-6 x-page-apps-desc__txt">
                {description.item}
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

const AppNavigationSection = ({ data }) => {
  const sectionRef = useRef()
  const elementRef = useRef()
  const [animated, setAnimated] = useState(false)
  /* animate element on scroll */
  const animateElement = () => {
    if (animated) return
    const documentTop = document.documentElement.scrollTop
    const documentBottom = documentTop + window.innerHeight
    if (sectionRef.current === null || elementRef.current === null) return
    const elementTop = sectionRef.current.offsetTop
    const elementBottom = elementTop + elementRef.current.clientHeight
    const elementVisible = elementBottom <= documentBottom && elementTop >= documentTop
    if (elementVisible) setAnimated(true)
  }
  useWindowEvent('scroll', animateElement)

  return (
    <section ref={sectionRef} className="x__width">
      <div ref={elementRef} className="x-page-apps-nav">
        <div className="x-page-apps__subheading">{data.heading}</div>
        <div className="x-page-apps-nav__img" />

        <div
          className={classNames('x-page-apps-nav__txt x-animate', {
            'x-animate--animated': animated,
          })}
        >
          {data.description.map((description, d) => (
            <div key={d} className="x-page-apps-nav__txt-item">
              {description.item}
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

const FeaturedSection = ({ data }) => {
  return data.apps.map((app, a) => (
    <section key={app.id} className={`x-page-apps-new x-page-apps-new--${app.id}`}>
      <div className="x__width">
        {a === 0 && <div className="x-page-apps__subheading">{data.heading}</div>}
        <img src={`/apps/img/${app.icon}`} className="x-page-apps-new__logo" />
        <Heading type="h2" page="apps" section={app.section} className="x-page-apps__subsection" />
        <span
          className="x-page-apps__subsection-txt"
          dangerouslySetInnerHTML={{ __html: app.description }}
        />

        <img
          src={app.header.image}
          alt={app.header.imageAlt}
          className="x-page-apps__rewards-assets"
        />

        <span
          className="x-page-apps__disclosure"
          dangerouslySetInnerHTML={{ __html: app.disclosure }}
        />

        <div className="x-apps-carousel-container">
          <div className={`x-apps-carousel x-apps-carousel--${app.id}`}>
            <AppsCarousel id={`x-${app.id}-carousel`}>
              {images.featuredSection[app.section].map((item, i) => (
                <Img
                  fluid={buildImageObject(item)}
                  durationFadeIn={1000}
                  alt={app.imageAlt}
                  key={i}
                />
              ))}
            </AppsCarousel>
          </div>

          {app.logosChrome && (
            <div className={`x-apps-carousel__${app.id}-chrome-container`} role="presentation">
              {[...Array(app.logosChrome)].map((chrome, c) => {
                const index = c + 1
                return (
                  <span
                    key={app.id}
                    className={`x-apps-carousel__${app.id}-chrome x-apps-carousel__${app.id}-chrome${index}`}
                    role={`presentation${index}`}
                  />
                )
              })}
            </div>
          )}
        </div>

        <a className={`x__button x-page-apps__btn x-page-apps__btn--${app.id}`} href={app.path}>
          <span className="x__button-align">
            <span className="x__button-align-text">Learn More</span>
          </span>
        </a>
      </div>
    </section>
  ))
}

const NewAppsSection = () => {
  return (
    <section className="x-page-apps-new x-page-apps-new--more">
      <div className="x__width">
        <img src="/apps/img/more-apps.svg" className="x-page-apps-new__logo" />
        <Heading type="h2" page="apps" section="apps" className="x-page-apps__subsection" />
      </div>
    </section>
  )
}

function AppsPage() {
  return (
    <PageWrapper className="x-page-apps" backgroundColor="rgb(23, 26, 30)">
      <HeaderSection data={AppsData.header} />
      <AppListSection data={AppsData.appList} />
      <DescriptionSection data={AppsData.information} />
      <AppNavigationSection data={AppsData.appNavigation} />
      <FeaturedSection data={AppsData.featured} />
      <NewAppsSection />
    </PageWrapper>
  )
}

export default AppsPage

// <head> component:
export function Head() {
  return <PageHead page="apps" />
}
